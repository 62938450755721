@import url('https://fonts.cdnfonts.com/css/amazon-ember');



body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //font-family: 'Lucida Console' sans-serif;
  font-family: 'Amazon Ember Display', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo{
  object-fit: contain;  
}

.search-dropdown .form-control:focus{
  box-shadow: none;
}

/* Add this to your CSS file or style tag */
.services-container {
  position: absolute;
  z-index: 1000;
  border-radius: 9px;
  height: 220px;
  padding: 9px;
  margin: 2px;
  width: 200px;
  right: 0;
  top: 100%;
  background-color: rgb(255, 255, 255, 10);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  opacity: 0;
  transform: translateY(-10px);
  pointer-events: none; /* Disable interaction when hidden */
}

.services-container.visible {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto; /* Enable interaction when visible */
}

.services-box{
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 10px;
  height: "100%";
}
.services-box > a{
  text-decoration: none;
  color: #71797E
}

.nav-link{
  color:rgb(9, 22, 30)
}
.services-box a{
  display: flex;
  height: 80px;
  width: 80px;
  border-radius: 9px;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center

}
.services-box img{
  height: 40px;
  width: 40px;
  cursor: pointer

}
.btn-primary, 
.btn-outline-primary{
  border: 0.1px groove #e1e8ed;
  background-color: transparent;
  touch-action: manipulation;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 4px rgba(0,0,0,0.1);
  padding: 1;
  background-image: linear-gradient(to bottom, #fff, #f8eedb);
  transition: all .1s cubic-bezier(.4, 0, .2, 1);
  overflow: hidden;
  color: #482307;
  font-weight: 600;
  font-size: 1.2rem
}
.products {
  display: flex;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.2px);
  -webkit-backdrop-filter: blur(7.2px);
  white-space: nowrap;
  overflow-x: auto;
  justify-content: space-evenly;
  align-items: center;
  }

.search-products{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    justify-content: space-between
  }
 
.rating span {
  color: #ffc000;
}

/* .product {
  max-height: 475px;
  box-shadow: 0 0 3px rgba(0,0,0,0.1);
  border-radius: 4px;
  height: 475px;
  border: 0.5px solid #e1e8ed;
  border: none;
  width: 100%;
  //margin-left: 10px;
} */

.product {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.card-img-wrapper {
  position: relative;
  height: 300px; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.productName {
  font-size: 1.25rem;
  font-weight: bold;
}

.card-info {
  position: relative;
  z-index: 2;
  padding: 15px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
  z-index: 1;
  color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}


button {
  z-index: 3;
}

.text-muted {
  color: rgba(255, 255, 255, 0.7);
}

.fas.fa-share {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  color: white;
}

.searchInput {
  border: none;
  padding: .8rem; 
  background-color: #f0f0f0;
  position: relative; 
}

.product img {
  width: 100%;
  max-height: 200px;
  object-fit: contain;
  padding: 12px
} 

.category{
  height: 220px;
}

.category img{
  width: 100%;
  height: 120px
}

.img-large {
  max-width: 400px;
  border: 3px solid #fafafb
}
.img-thumbnail {
  height: 80px;
}
.small-container {
  max-width: 600px;
}

.checkout-steps > div {
  border-bottom: 0.2rem solid mediumaquamarine;
  color: mediumaquamarine;
}
.checkout-steps > div.active {
  border-bottom: 0.2rem solid teal;
  color: teal;
}

.site-container {
  transition: 0.4s;
  //height: 100vh
}
.active-cont {
  margin-left: 240px;
}
.active-nav {
  left: 0;
}
.text-bold {
  font-weight: bold;
}

.map-input-box {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0.5rem auto;
  width: 25rem;
  height: 3rem;
  display: flex;
}
.map-input-box input {
  border-radius: 1rem 0 0 1rem;
  border-right: 0;
}
.map-input-box button {
  border-radius: 0 1rem 1rem 0;
  border-left: 0;
}
button.thumbnail {
  padding: 0;
}

.categLink{
  color: antiquewhite;
}

.featured{
  display: flex;
  overflow-x: auto;  
  box-shadow: 0 4px 1px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(4.2px);
}

.categories{
  background: rgba(255, 255, 255, 0.25);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.2px);
  -webkit-backdrop-filter: blur(7.2px);
  padding-top: 12px;
  padding-bottom: 22px;
}
.navigation{
  //box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  display: flex;
  margin: auto;
  padding: 0;
  margin: 0;
  justify-content: space-between;
  height: 72px;
  width: 100%;
  align-content: center;
  background-image: linear-gradient(#80d0c7 100%, 15deg, #13547a 0%);
  justify-items: center
 
}

.navigation i {
  text-decoration: none;
  font-size: 21px;
  color: black;
  padding-bottom: 0;
  flex-direction: "column";
  //justify-self: center
}


.right-nav-icons a{
  text-decoration: none;
  margin: 0;
  padding: 0;
  align-self: center;
  color: teal;
  font-weight: 900;
  font-size: 20px;
}


.card-img-top{
  max-width: 100%;
  //max-width: 400px;
}

.headerLink{
  color: teal;
  height: 26px;
  font-weight: 9000;
  justify: center
}

.categories-container img{
  border-radius: 50%;
  width: 53px;
  padding:0
}
.nav-icon{
  color: black
}

.profile-icon{
  border-radius: 50%;
  padding: 12px;
  //border: 2px solid black;
  background: white;
  color: white
}
.shop-logo-container{
  width: 100px; /* Adjust the size of your circle */
  height: 100px; /* Adjust the size of your circle */
  border-radius: 50%; /* Makes the container a circle */
  overflow: hidden; /* Ensures the logo doesn't overflow the circle */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  object-fit: cover;
}

.logout{
  position: fixed;
  bottom: 0;
}
.store-card{
  max-height: 100px;
  width: 97%;
  justify-content: center;
  height: 108px;
  padding: 9;
  overflow: hidden;
  background: #fff;
  overflow: hidden
}


.search-box-container {
  width: 100%;
  margin: auto;
  max-height: 300px;
}

.search-results-list {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 1000;
}

.search-results-list .list-group-item {
  cursor: pointer;
  padding: 10px;
}

.search-results-list .list-group-item:hover {
  background-color: #f0f0f0;
}

h5 {
  margin-top: 20px;
  font-size: 16px;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  padding: 5px 0;
}


.horizontal-scroll {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 20px;
  justify-content: space-between;
  box-shadow: 0 4px 1px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(3.2px);
}

.horizontal-scroll > * {
  flex: 0 0 auto;
  margin-right: 10px;
}

.horizontal-item img {
  max-height: 100px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 0.6px solid rgba(0, 0, 0, 0.1);
  padding: 1px;
  border-radius: 5%;

}

.navigation-container {
  display: flex;
  width: 100%;
  margin-vertical: 12px
}

.categories-scroll,
.subcategories-scroll {
  overflow-x: auto;
  white-space: nowrap;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}


.categories-container,
.subcategory-container {
  display: flex;
  width: 100%
}

.category-tab {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eeeeee;
  flex-grow: 1; /* Allow the category tabs to grow to fill the space */
}

.category-tab.active {
  background-color: #eeeeee;
}

.subcategory-container {
  padding: 0px;
  width: 100%;
  overflow-x: auto;
  justify-content: space-between;
  background: #eeeeee;
}
.subcategory-container a{
  text-decoration: none;
  font-weight: 8000
}


.text-uppercase{
  font-weight: 800;
  font-style: bold
}
.offer-b-img{
  max-height: 140px;
  height: 140px;
  padding: 12px;
  object-fit: contain
}

